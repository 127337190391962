jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };
  
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchmove", handle, { passive: false });
      } else {
        this.addEventListener("touchmove", handle, { passive: true });
      }
    }
  };
  
  
  /**** Main menu ****/
  
  $('.dropdown-toggle').dropdown();
  
  function checkWidth() {
    if ($(window).width() < 975) {
      $(".navi li ul").hide();
  
    } else {
      $(".navi li ul").attr('style', '');
  
    }
  }
  
  checkWidth();
  
  $(window).on("resize", function () {
    checkWidth();
    $('a.collapse').hide();
    $('a.expand').show();
  });
  
  $(".navi li").each(function () {
    var li = $(this);
    if (li.find('ul li').length > 0) {
      li.append("<a href='javascript:;' rel='nofollow noopener' class='expand icon-expand' onclick='return false;'></a>");
      li.append("<a href='javascript:;' rel='nofollow noopener' class='collapse icon-collapse' onclick='return false;'></a>");
    }
  });
  
  $('body').on('click', 'a.expand', function () {
    $(this).hide().next().show();
    $(this).parent().siblings().find('ul').slideUp();
    $(this).parent().siblings().find('a.expand').show();
    $(this).parent().siblings().find('a.collapse').hide();
    $(this).parent().find('>ul').addClass('expanded').stop(true, true).slideDown();
  });
  
  $('body').on('click', 'a.collapse', function () {
    $(this).parent().find('ul').removeClass('expanded').stop(true, true).slideUp();
    $(this).parent().find('a.collapse').hide();
    $(this).parent().find('a.expand').show();
  });
  
  $(".menu-btn").on('click', function () {
    $(".menu-btn").toggleClass("is-active");
    $(".header-container").toggleClass("navi-open");
    $("body").toggleClass("navi-open");
    $("body").css("overflow", ""); // Reset the body overflow property
  });
  
  
  /**** Main menu end ****/
  

  

  

  